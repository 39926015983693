import React from "react";
import Layout from "../components/layout";
import Wrapper from "../components/wrapper/Wrapper";
import Section from "../components/section/Section";
import SEO from "../components/seo";
import styled from "styled-components";
import { links } from "../data/data";
import { iyengarMessage } from "../data/data";

const LinksContainer = styled.div`
  margin-top: 24px;

  h3 {
    color: var(--black);
  }

  ul {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;
  }
`;

const Iyengar = () => {
  return (
    <Layout>
      <SEO title="アイアンガーヨガについて|ヨガスタジオ笠置" />
      <Wrapper>
        <Section className="active">
          <article className="article-container">
            <h1 className="title">アイアンガーヨガ</h1>
            <p>{iyengarMessage}</p>
            <LinksContainer>
              <h3>外部リンク</h3>
              <ul>
                {links.map((link, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={link.link}
                        rel="noreferrer"
                        target="_blank"
                        className="link"
                      >
                        {link.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </LinksContainer>
          </article>
        </Section>
      </Wrapper>
    </Layout>
  );
};

export default Iyengar;
