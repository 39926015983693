import React from "react";

const Section = ({ children, header, id, className }) => {
  return (
    <section
      className={`${className ? className : ""} section-wrapper`}
      id={id}
    >
      {header ? <h2>{header}</h2> : null}
      <div className="section-main">{children}</div>
    </section>
  );
};

export default Section;
