import React from "react";

export const features = [
  {
    id: 1,
    title: "身体が変わってきます",
    description:
      "続けて練習すると縮んだ筋肉が伸びて柔軟性が生まれてきます。そして補助道具を使うことによって正しいポーズを保つことができ、身体の歪みを整えていきます。",
    color: "#f85b1d",
  },
  {
    id: 2,
    title: "身体が変わると元気になります",
    description:
      "閉じていた胸が開いてきます。胸が開くと自然と元気が湧いてきます。そして、気持ちも前向きになり、呼吸も改善されます。",
    color: "#f85b1d",
  },
  {
    id: 3,
    title: " 脳が活性化します",
    description:
      "練習を続けていくと、血流やリンパの流れがスムーズになり、細胞を活性化して免疫力を高めます。特に、逆転のポーズは新鮮な血液や酸素が脳へいきます。その結果、静かな状態になります。これが活性化へと繋がります。",
    color: "#f85b1d",
  },
];

const DaysEnum = Object.freeze({
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
});

export const JapaneseDate = Object.freeze({
  1: "月",
  2: "火",
  3: "水",
  4: "木",
  5: "金",
  6: "土",
  7: "日",
});

export const schedule = [
  {
    date: DaysEnum.monday,
    available: [["09:00 - 10:30"]],
    memo: "",
  },
  {
    date: DaysEnum.tuesday,
    available: [["09:30 - 11:00"]],
    memoo: "",
  },
  {
    date: DaysEnum.wednesday,
    available: [["10:30 - 12:00"]],
    memo: "シニア対象",
  },
  {
    date: DaysEnum.saturday,
    available: [["07:00 - 08:30"], ["12:00 - 13:30"]],
  },
];

export const aboutMessage = () => {
  return (
    <p style={{ marginTop: "16px" }}>
      水谷さおり １９５７年２月東京生まれ
      ２０代はサーフィンに明け暮れ、全日本サーフィン大会で準優勝2回の結果を残す。その後、プロサーファーになり活動。
      その後、夫の仕事の関係で海外の国々に２５年程暮らす。テニスを夢中にやっていたが、友人に誘われて興味本位で行ってみたアイアンガーヨガのクラスに出て、あまりの気持ちよさに驚く。
      しばらく両方やっていたが、ある日テニスは死ぬまでできないが、ヨガは死ぬまでできると気づき、その日を境にテニスはやめて、ヨガだけをすることになる。
      そして、この気持ちよさを他の人にもシェアしたいと思うようになり、指導者を目指す。
      インド・ダラムサラで３ヶ月のティーチャーズトレーニングにカリブのセントルシアより参加。その後、移り住んだペルーで初めて始まったティーチャーズトレーニングに参加。
      ２年後、イントロダクトリー１のアセスメントに合格。さらに１年後、イントロダクトリー２に合格し
      {
        <a
          rel="noreferrer"
          target="_blank"
          className="link"
          href="https://bksiyengar.com/modules/Teacher/teacher.asp?Country=Japan&State=Gifu"
        >
          認定指導員
        </a>
      }
      となる。
      帰国し、埼玉県志木市にあるアイアンガーヨガセンターで上級指導員のビンダハニ純子先生の元で指導を受け、学びを深める。
      その後、岐阜に定住しようと先に住み始めた夫に合流。恵那市に引っ越し、笠置山の麓に理想の古民家を見つけ、改装してヨガスタジオを作りました。
    </p>
  );
};

export const links = [
  {
    title: "B.K.S.Iyengar Official website",
    link: "https://bksiyengar.com/",
  },
  {
    title: "日本アイアンガーヨガ協会",
    link: "http://www.iyengar-yoga.jp/",
  },
  {
    title: "アイアンガーヨガセンター",
    link: "https://yogamarga.com/",
  },
  {
    title: "CENTRO DE YOGA IYENGAR PERU",
    link: "http://yogaiyengarperu.com/",
  },
  {
    title: "yoga studio AIEN",
    link: "https://yoga.studioaien.com/",
  },
];

export const iyengarMessage = `
アイアンガーヨガは、あらゆる年齢、体型、フィットネスレベルに対応しています。アイアンガーヨガは、身体の健康と活力、頭脳の明晰さ、感情的な静けさ、そして精神的な幸福を生み出します。具体的には、補助道具を使うことによって、無理なく正しい身体の繋がりを保つことができます。そして、目的に応じた順番に沿って練習することによって効果を最大限に引き出せます。
`;
